import React from "react"
import Layout from "../components/Layout"
import HeroHeader from "../components/HeroHeader"
import InformationSection from "../components/InformationSection"
import { SEO as Seo } from "../components/SEO.js"
import { graphql } from "gatsby"

export default function Home({ data }) {
  const seoImage = data.file.publicURL
  return (
    <Layout>
      <Seo
        title="Blackfort - Software Development Company in the Philippines"
        image={"https://blackfort.ph" + seoImage}
      />
      <HeroHeader />
      <InformationSection />
    </Layout>
  )
}

export const query = graphql`
  query MyQuery {
    file(relativePath: { eq: "thumbnail-blackfort.jpg" }) {
      id
      publicURL
    }
  }
`
