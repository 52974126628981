import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Spring } from "react-spring/renderprops"
import { easePolyOut } from "d3-ease"
import styled from "styled-components"
import Multimedia from "./multimedia/blackfortheader1.webm"
import { MPLarge, MPNormal } from "../styles/PageStyles.js"

const HeroHeader = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
        frontmatter {
          description
        }
      }
    }
  `)

  const { frontmatter } = data.markdownRemark

  return (
    <HeroContainer>
      <Hero>
        <Video autoPlay muted loop playsInline>
          <source src={Multimedia + "#t=0.5"} type="video/webm" />
        </Video>
      </Hero>
      <Content>
        <Spring
          from={{ opacity: 0, marginLeft: -500 }}
          to={{ opacity: 1, marginLeft: 0 }}
          config={{ easing: easePolyOut }}
        >
          {props => (
            <div style={props}>
              <div>
                <MPLarge style={{ fontWeight: 500 }}>
                  Successful
                  <br />
                  transformation
                  <br />
                  starts with{" "}
                  <Highlight>
                    IT <br /> modernization
                  </Highlight>
                </MPLarge>
              </div>
              <Paragraph>
                <MPNormal>{frontmatter.description}</MPNormal>
              </Paragraph>
            </div>
          )}
        </Spring>
      </Content>
    </HeroContainer>
  )
}

export default HeroHeader

const HeroContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0 1rem;
  position: relative;
  color: white;
`

const Hero = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const Video = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
`

const Content = styled.div`
  z-index: 3;
  display: flex;
  align-items: center;
  height: calc(100vh - 80px);
  max-height: 100%;
  padding: 0rem calc((100vw - 1300px) / 2);

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`

const Highlight = styled.span`
  color: #ffb700;
  font-family: "Maven Pro";
  font-weight: 900;
  // font-size: 48px;
  font-size: clamp(32px, 5vw, 48px);
`

const Paragraph = styled.div`
  margin-top: 1.5rem;
  width: 40%;

  @media screen and (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 100%;
  }
`