import React from "react"
import styled from "styled-components"
import { Col, Container, Row, Image } from "react-bootstrap"
import { Link, graphql, useStaticQuery } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import { Spring, animated } from "react-spring/renderprops"
import VisibilitySensor from "react-visibility-sensor"
import { easePolyOut } from "d3-ease"
import { Button } from "./Button"
import Carousel from "react-elastic-carousel"
import gif from "./multimedia/zoom-in.gif"
import logo from "./multimedia/bf_logo.svg"
import {
  RobotoLarge,
  RobotoLarge2,
  RobotoSmall,
  RobotoNormal,
  RobotoNormal2,
  MPMedium,
  MPAccent,
} from "../styles/PageStyles.js"

export const ServicesTemplate = ({ services }) => (
  <Row lg={3} xs={1} style={{ paddingBottom: "10vh" }}>
    {services.map((service, index) => (
      <StyledCol
        isCentered
        isPadded
        data-aos="fade-up"
        data-aos-duration="500"
        key={index}
        data-aos-delay={service.delay}
        data-aos-offset={service.offset}
      >
        <div style={{ width: "100%" }}>
          <Image
            src={service.image.publicURL}
            alt={service.title}
            height="250"
            style={{
              objectFit: service.fit,
              background: service.background,
              width: "100%",
            }}
          />
        </div>
        <div className="service-title">
          <RobotoNormal style={{ color: "#000000" }}>
            {service.title}
          </RobotoNormal>
        </div>
      </StyledCol>
    ))}
  </Row>
)

const InformationSection = () => {
  const breakPoints = [
    { width: 500, itemsToShow: 1 },
    { width: 768, itemsToShow: 2 },
    { width: 1024, itemsToShow: 3 },
  ]

  const data = useStaticQuery(graphql`
    query {
      index: markdownRemark(
        frontmatter: { templateKey: { eq: "index-page" } }
      ) {
        frontmatter {
          description
          whoWeAre {
            description
            enterprises
            smb
            years
          }
          ourServices {
            description
          }
          ourWorks {
            description
            image1 {
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            image2 {
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            image3 {
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            image4 {
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          ourClients {
            image1 {
              publicURL
            }
            image2 {
              publicURL
            }
            image3 {
              publicURL
            }
          }
          banner {
            background {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: DOMINANT_COLOR
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            main
            sub
          }
        }
      }
      services: markdownRemark(
        frontmatter: { templateKey: { eq: "services-page" } }
      ) {
        frontmatter {
          ourServices {
            services {
              image {
                publicURL
              }
              title
              fit
              background
              delay
              offset
            }
          }
        }
      }
    }
  `)

  const { frontmatter } = data.index
  const { services } = data.services.frontmatter.ourServices
  const image = getImage(frontmatter.ourWorks.image1)
  const image2 = getImage(frontmatter.ourWorks.image2)
  const image3 = getImage(frontmatter.ourWorks.image3)
  const image4 = getImage(frontmatter.ourWorks.image4)
  const client1PublicURL = data.index.frontmatter.ourClients.image1.publicURL
  const client2PublicURL = data.index.frontmatter.ourClients.image2.publicURL
  const client3PublicURL = data.index.frontmatter.ourClients.image3.publicURL
  const bannerBG = getImage(frontmatter.banner.background)

  return (
    <Container fluid>
      <AnniversaryBanner data-aos="zoom-out" data-aos-delay="200">
        <StaticImage
          src="./multimedia/blackfort-11th-anniversary-banner.png"
          alt="Blackfort 11th Anniversary!"
          className="anniversary"
          placeholder="blurred"
          formats={["auto", "webp", "avif"]}
        />
      </AnniversaryBanner>
      <VisibilitySensor partialVisibility offset={{ top: 50, bottom: 50 }}>
        {({ isVisible }) => (
          <Spring
            to={{ opacity: isVisible ? 1 : 0 }}
            config={{ duration: 500, easing: easePolyOut }}
          >
            {props => (
              <div style={props}>
                <StyledRow>
                  <StyledColumn sm={7}>
                    <CenteredDiv2>
                      <StaticImage
                        src="./multimedia/who-we-are.png"
                        alt="Who Are We"
                      />
                    </CenteredDiv2>
                    <StyledImage src={gif} alt="GIF" />
                  </StyledColumn>
                  <StyledColumn2
                    sm={5}
                    data-aos="fade-left"
                    data-aos-delay="100"
                  >
                    <StyledImage3 src={logo} alt="LOGO" />
                    <Paragraph>
                      <RobotoNormal2>
                        {frontmatter.whoWeAre.description}
                      </RobotoNormal2>
                    </Paragraph>

                    {/* YEARS OF OPERATION */}
                    <Row
                      style={{
                        width: "90%",
                      }}
                    >
                      <StyledCol row lg={12} xs={12}>
                        <VisibilitySensor
                          partialVisibility
                          offset={{ top: 50, bottom: 50 }}
                        >
                          {({ isVisible }) => (
                            <Spring
                              to={{
                                number: isVisible
                                  ? frontmatter.whoWeAre.years
                                  : 0,
                              }}
                              config={{ duration: 2000, easing: easePolyOut }}
                            >
                              {props => (
                                <StyledCol verticalAlign style={props} xs={4}>
                                  <RobotoLarge2 style={{ color: "#FFB700" }}>
                                    {props.number.toFixed()}+
                                  </RobotoLarge2>
                                  <RobotoSmall
                                    lineBreak
                                    style={{
                                      color: "#64707A",
                                      textAlign: "center",
                                    }}
                                  >
                                    YEARS OF OPERATION
                                  </RobotoSmall>
                                </StyledCol>
                              )}
                            </Spring>
                          )}
                        </VisibilitySensor>
                        <VisibilitySensor
                          partialVisibility
                          offset={{ top: 50, bottom: 50 }}
                        >
                          {({ isVisible }) => (
                            <Spring
                              to={{
                                number: isVisible
                                  ? frontmatter.whoWeAre.smb
                                  : 0,
                              }}
                              config={{ duration: 2000, easing: easePolyOut }}
                            >
                              {props => (
                                <StyledCol verticalAlign style={props} xs={4}>
                                  <RobotoLarge2 style={{ color: "#FFB700" }}>
                                    {props.number.toFixed()}+
                                  </RobotoLarge2>
                                  <RobotoSmall
                                    lineBreak
                                    style={{
                                      color: "#64707A",
                                      textAlign: "center",
                                    }}
                                  >
                                    SMB
                                  </RobotoSmall>
                                </StyledCol>
                              )}
                            </Spring>
                          )}
                        </VisibilitySensor>
                        <VisibilitySensor
                          partialVisibility
                          offset={{ top: 50, bottom: 50 }}
                        >
                          {({ isVisible }) => (
                            <Spring
                              to={{
                                number: isVisible
                                  ? frontmatter.whoWeAre.enterprises
                                  : 0,
                              }}
                              config={{ duration: 2000, easing: easePolyOut }}
                            >
                              {props => (
                                <StyledCol verticalAlign style={props} xs={4}>
                                  <RobotoLarge2 style={{ color: "#FFB700" }}>
                                    {props.number.toFixed()}
                                  </RobotoLarge2>
                                  <RobotoSmall
                                    lineBreak
                                    style={{
                                      color: "#64707A",
                                      textAlign: "center",
                                    }}
                                  >
                                    ENTERPRISE
                                  </RobotoSmall>
                                </StyledCol>
                              )}
                            </Spring>
                          )}
                        </VisibilitySensor>
                      </StyledCol>
                    </Row>
                  </StyledColumn2>
                </StyledRow>
              </div>
            )}
          </Spring>
        )}
      </VisibilitySensor>

      <Row>
        {/* OUR SERVICES */}
        <StyledCol
          lineBreak
          isCentered
          cover
          paddingLeftRight
          lg={12}
          style={{
            background: "#0A0E2A",
            paddingTop: "10vh",
          }}
        >
          <StyledCol lg={12} row column_M lineBreak>
            <Row>
              <StyledCol lg={4} isCentered>
                <Title>
                  <RobotoLarge>Our Services</RobotoLarge>
                </Title>
              </StyledCol>
              <StyledCol lg={8} isCentered>
                <RobotoNormal2 alignCenter>
                  {frontmatter.ourServices.description}
                </RobotoNormal2>
              </StyledCol>
            </Row>
          </StyledCol>
          <ServicesTemplate services={services} />
        </StyledCol>

        {/* OUR WORKS */}
        <StyledCol lg={12}>
          <Row>
            <StyledCol lg={12} row column_M lineBreak paddingLeftRight>
              <Row>
                <StyledCol lg={4} isCentered>
                  <Title>
                    <RobotoLarge>Our Works</RobotoLarge>
                  </Title>
                </StyledCol>
                <StyledCol lg={8} isCentered>
                  <RobotoNormal2 alignCenter>
                    {frontmatter.ourWorks.description}
                  </RobotoNormal2>
                </StyledCol>
              </Row>
            </StyledCol>
            <StyledCol
              lg={12}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                padding: "0",
              }}
            >
              <StyledCol
                lg={3}
                xs={4}
                style={{
                  background: "#FF662B",
                  width: "200px",
                  height: "30px",
                }}
              ></StyledCol>
              <StyledCol
                lg={3}
                xs={4}
                style={{
                  background: "#FFB700",
                  width: "200px",
                  height: "30px",
                }}
              ></StyledCol>
            </StyledCol>
            <Row>
              <StyledCol
                lg={6}
                data-aos="fade-right"
                data-aos-delay="200"
                data-aos-duration="500"
              >
                <GatsbyImage image={image} alt="Work 1" />
              </StyledCol>
              <StyledCol
                lg={6}
                data-aos="fade-left"
                data-aos-delay="400"
                data-aos-duration="500"
              >
                <GatsbyImage image={image2} alt="Work 2" />
              </StyledCol>
              <StyledCol
                lg={6}
                data-aos="fade-right"
                data-aos-delay="400"
                data-aos-duration="500"
                data-aos-offset="0"
              >
                <GatsbyImage image={image3} alt="Work 3" />
              </StyledCol>
              <StyledCol
                lg={6}
                lineBreak
                data-aos="fade-left"
                data-aos-delay="600"
                data-aos-duration="500"
                data-aos-offset="0"
              >
                <GatsbyImage image={image4} alt="Work 4" />
              </StyledCol>
            </Row>
            <StyledCol lg={12} isCentered lineBreak>
              <Link className="show-all-link" to="/projects">
                <span>Show All</span>
              </Link>
            </StyledCol>
          </Row>
        </StyledCol>

        {/* OUR CLIENTS */}
        <StyledCol lg={12} isCentered lineBreak>
          <Title>
            <RobotoLarge>Our Clients</RobotoLarge>
          </Title>
        </StyledCol>
      </Row>

      <StyledCarousel
        breakPoints={breakPoints}
        enableAutoPlay
        autoPlaySpeed={3000}
      >
        <CarouselItem>
          <Image src={client1PublicURL} alt="Byaheros" />
        </CarouselItem>
        <CarouselItem>
          <Image src={client2PublicURL} alt="Accenture" />
        </CarouselItem>
        <CarouselItem>
          <Image src={client3PublicURL} alt="Cebu Pacific" />
        </CarouselItem>
        {/* <CarouselItem>
          <Image src={rhi} alt="Roxas Holdings" />
        </CarouselItem>
        <CarouselItem>
          <Image src={caemc} alt="CAEMC" />
        </CarouselItem> */}
      </StyledCarousel>

      {/* BOTTOM BANNER SECTION */}
      <Row>
        <StyledCol lg={12} data-aos="zoom-out" data-aos-delay="200">
          <Banner>
            <CenteredDiv>
              <MPMedium normal>{frontmatter.banner.main}</MPMedium>
              <MPAccent lineBreak>{frontmatter.banner.sub}</MPAccent>
              <StyledButton
                primary="true"
                round="true"
                to="/project-estimation"
              >
                Let's Talk
              </StyledButton>
            </CenteredDiv>
            <GatsbyImage
              image={bannerBG}
              alt="Banner"
              className="bottomBanner"
              objectPosition="center bottom"
            />
          </Banner>
        </StyledCol>
      </Row>
    </Container>
  )
}

export default InformationSection

// STYLED COMPONENTS

const AnniversaryBanner = styled.div`
  padding: 50px 0px 50px 0px;
  margin: 0;

  @media screen and (max-width: 767px) {
    padding: 20px 0px 20px 0px;
    height: 200px;

    .anniversary {
      height: 100%;
    }
  }
`

const CarouselItem = styled.div`
  width: 100%;
  height: 200px;
  border-bottom-right-radius: 30px;
  margin: 20px;
  background: #1d1d1d;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  img {
    height: 50px;
    object-fit: contain;
  }
`

const StyledCarousel = styled(Carousel)`
  .rec-arrow {
    display: none;
  }

  .rec-dot {
    background: #333;
    box-shadow: none;
  }

  .rec-dot_active {
    background: #ffb700;
  }
`

const StyledCol = styled(animated(Col))`
  display: flex;
  justify-content: ${({ isCentered }) => (isCentered ? "center" : undefined)};
  justify-content: ${({ spaceBetween }) =>
    spaceBetween ? "space-between" : undefined};
  align-items: ${({ isCentered }) => (isCentered ? "center" : undefined)};
  align-items: ${({ verticalAlign }) => (verticalAlign ? "center" : undefined)};
  flex-direction: ${({ row }) => (row ? "row" : "column")};
  width: ${({ cover }) => (cover ? "100%" : undefined)};
  height: ${({ cover }) => (cover ? "100%" : undefined)};
  padding: ${({ isPadded }) => (isPadded ? "15px !important" : "0")};
  padding: ${({ paddingLeftRight }) =>
    paddingLeftRight ? "0 10% 0 10%" : undefined};
  border-bottom-right-radius: ${({ styledBorder }) =>
    styledBorder ? "20px" : undefined};
  margin-bottom: ${({ lineBreak }) => (lineBreak ? "10vh" : "0")};

  @media screen and (max-width: 768px) {
    flex-direction: ${({ column_M }) => (column_M ? "column" : undefined)};
  }

  .service-title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 54px;
    width: 100%;
    border-bottom-right-radius: 20px;
    background: #ffb700;
  }

  .show-all-link {
    span {
      font-weight: normal;
      font-size: clamp(16px, 5vw, 18px);
      line-height: 26px;
      &:after {
        content: "";
        display: block;
        width: -0;
        height: 3px;
        background: #ffb700;
        transition: 0.3s ease-in-out;
      }

      &:hover {
        display: block;
        color: #ffb700;
        transform: translateY(-2px);
        transition: 0.3s ease-in-out;
        &:after {
          width: 100%;
        }
      }
    }
  }
`

const StyledRow = styled(Row)`
  position: relative;
  background: #061727;
  display: block;
  overflow: hidden;
`

const StyledColumn = styled(Col)`
  position: relative;
  padding-right: 0;
  padding-left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 900px;
  overflow: hidden;
  margin-bottom: -80px;
  margin-top: -80px;

  @media screen and (max-width: 768px) {
    height: 100%;
    margin-bottom: 0;
    margin-top: 0;
  }
`

const StyledColumn2 = styled(Col)`
  background: #061727;
  position: absolute;
  padding: 10vh;
  padding-right: 0;
  padding-left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  left: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 576px) {
    left: 0;
    position: relative;
  }
`

const StyledImage = styled(Image)`
  position: relative;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  clip: rect(80px 900px 825px 0px);

  @media screen and (max-width: 768px) {
    min-height: 70vh;
    margin-top: -80px;
    margin-bottom: -80px;
  }
`

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: right;
  margin-right: 30px;

  @media screen and (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 5vh;
    text-align: center;
  }
`

const CenteredDiv2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 25%;
  bottom: 25%;
  left: 25%;
  right: 25%;
  z-index: 1;

  &:before {
    content: "";
    display: inline-block;
    height: 100%;
  }
`

const StyledImage3 = styled(Image)`
  margin-bottom: 5vh;
`
const Paragraph = styled.div`
  width: 80%;
  margin-bottom: 5vh;

  @media screen and (max-width: 768px) {
    // margin-bottom: 10vh;
    text-align: center;
  }

  @media only screen and (width: 1024px) {
    margin-bottom: 5vh;
  }
`

const Banner = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding-top: 10vh;

  & .bottomBanner {
    display: block;
    height: 80vh;
    width: 100%;
    z-index: -1;
    object-fit: cover;
    object-position: center bottom;
    @media screen and (max-width: 768px) {
      height: 80vh;
      object-fit: cover;
      object-position: center;
    }
  }
`

const CenteredDiv = styled.div`
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  text-align: center;
  font-size: 16px;
  width: 50%;

  &:before {
    content: "";
    display: inline-block;
    height: 100%;
  }
`

const StyledButton = styled(Button)`
  margin-top: 5vh;
  background: #ff4700 !important;
`
